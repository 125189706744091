.boxLoading {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loaderLine {
    width: 200px;
    height: 3px;
    position: relative;
    overflow: hidden;
    background-color: #000;
    margin: 15px auto;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    &:before {
      content: '';
      position: absolute;
      left: -50%;
      height: 3px;
      width: 40%;
      background-color: #ff9900;
      -webkit-animation: lineAnim 1s linear infinite;
      -moz-animation: lineAnim 1s linear infinite;
      animation: lineAnim 1s linear infinite;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}
