body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.luongson-menu-inline {
  border-inline-end: none !important;
}
.textSort {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: inherit;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  width: 100%;
  text-align: start;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 6px;
  outline: 0;
  transition: all 0.2s linear;
  appearance: textfield;
}
.textSort,
.textSort::placeholder {
  color: #000;
}
